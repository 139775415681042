import "./styles.scss";
import "../../../global.scss";
import { Button, Grid, TextField } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useRef, useState } from "react";
import { pageSizeOptions } from "../../../helper";
import {
  DataGrid,
  GridActionsCellItem,
  useGridApiRef,
  DEFAULT_GRID_AUTOSIZE_OPTIONS,
} from "@mui/x-data-grid";
import rentalCategoryService from "../../../services/rentalCategory.service";
import { useOutletContext } from "react-router-dom";
import RentalCategoryForm from "./form";

export default function RentalCategoryPanel() {
  const menuOpen = useOutletContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [rows, setRows] = useState([]);
  const [rowsCount, setRowsCount] = useState(1);
  //   const [confirm, setConfirm] = useState({
  //     open: false,
  //     message: "Are you sure to delete this data?",
  //     id: "",
  //   });
  const [filter, setFilter] = useState("");
  const apiRef = useGridApiRef();
  const ref = useRef();
  const [formOpen, setFormOpen] = useState(false);
  const [formId, setFormId] = useState(0);

  useEffect(() => {
    getList();
  }, [paginationModel]);

  const columns = [
    { field: "desc", headerName: "Kategori", flex: 1 },
    {
      field: "id",
      headerName: "Action",
      type: "actions",
      maxWidth: 200,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label="Ubah"
          title="Ubah"
          onClick={() => onEdit(params.row)}
        />,
        <GridActionsCellItem
          icon={<DeleteIcon />}
          label="Hapus"
          title="Hapus"
          onClick={() => onDelete(params.row)}
        />,
      ],
    },
  ];

  const onEdit = (row) => {
    setFormId(row.id);
    setFormOpen(true);
  };

  const onDelete = async (row) => {
    // setConfirm((prev) => ({ ...prev, open: true, id: row.username }));
    if (window.confirm("Anda yakin untuk menghapus [" + row.desc + "] ?")) {
      await rentalCategoryService
        .remove({
          id: row.id,
        })
        .then((response) => {
          alert("Hapus Data Sukses");
          getList();
        })
        .catch((err) => {
          alert(err);
        })
        .finally(() => {});
    }
  };

  const getList = async () => {
    setIsLoading(true);
    await rentalCategoryService
      .list({
        filter: filter,
        page: paginationModel.page,
        size: paginationModel.pageSize,
      })
      .then((response) => {
        // for (let i = 0; i < response.records.length; i++) {
        //   response.records[i].id = i + 1;
        // }
        setRows(response.records);
        setRowsCount(response.total);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onRefresh = () => {
    getList();
  };

  const onAdd = () => {
    setFormId(0);
    setFormOpen(true);
  };
  const closeForm = () => {
    setFormOpen(false);
  };
  const successForm = () => {
    setFormOpen(false);
    getList();
  };

  return (
    <>
      <div className="rental-page base-page">
        <div className="base-page-title">Kategori Sewa/Rental List</div>
        <div className="base-page-content">
          <div className="base-page-filter">
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextField
                  type="text"
                  placeholder="Filter key"
                  fullWidth
                  size="small"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Button variant="contained" color="success" onClick={onRefresh}>
                  Refresh
                </Button>
              </Grid>
              <Grid item xs={12} md={7} className="text-right" onClick={onAdd}>
                <Button color="secondary">Tambah Kategori</Button>
              </Grid>
            </Grid>
          </div>
          <div className="base-page-table" ref={ref}>
            <DataGrid
              apiRef={apiRef}
              className="datagrid-height"
              rows={rows}
              rowCount={rowsCount}
              columns={columns}
              loading={isLoading}
              paginationMode="server"
              pageSizeOptions={pageSizeOptions}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
            />
          </div>
        </div>
      </div>
      {formOpen && (
        <RentalCategoryForm
          id={formId}
          onCancel={closeForm}
          onSuccess={successForm}
        />
      )}
    </>
  );
}
