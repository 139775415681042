import { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import { Button, Grid, TextField, Select, FormControl } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import rentalCategoryService from "../../../../services/rentalCategory.service";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import noImage from "../../../../assets/images/no-image.png";
import axios from "axios";

export default function RentalCategoryForm(props) {
  const id = props.id;

  const [base64, setBase64] = useState("");
  const [desc, setDesc] = useState("");
  const [imgChange, setImgChange] = useState(0);

  let onProcess = false;
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (id != 0) getRentalCategory();
  }, [id]);

  const uploadImage = async (e) => {
    let image = e.target.files[0];
    if (image == undefined) return false;
    let fileSize = Math.round(image.size / 1024);
    if (fileSize > 2048) {
      alert("File Image tidak boleh lebih dari 2MB");
      return false;
    }

    const formData = new FormData();
    formData.append("image", image);

    axios
      .post("https://indopart.co.id/webservices/upload_image.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setBase64(response.path);
        setImgChange(1);
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });

    // const fr = new FileReader();
    // fr.addEventListener("load", (evt) => {
    //     setBase64(evt.target.result);
    // });
    // fr.readAsDataURL(e.target.files[0]);
    // setImgChange(1);
  };

  const getRentalCategory = async () => {
    await rentalCategoryService
      .get({
        id: id,
      })
      .then((response) => {
        setDesc(response.desc);
        setBase64(response.image);
        setImgChange(0);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {});
  };

  const onSubmit = async () => {
    if (onProcess) return false;
    setIsSubmit(true);

    onProcess = true;

    let params = {
      id,
      desc,
      imagePath: base64,
      imgChange,
    };

    await rentalCategoryService
      .submit(params)
      .then((response) => {
        alert("Simpan Data Sukses");
        props.onSuccess();
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        onProcess = false;
      });
  };

  const onCancel = () => {
    if (onProcess) return false;
    props.onCancel();
  };

  return (
    <>
      <div className="rental-form">
        <div className="form-box">
          <div className="form-title">Kategori Sewa/Rental Form</div>
          <div className="form-content">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="preview-image-container">
                  {base64 == "" && <img src={noImage} alt="img banner" />}
                  {base64 != "" && <img src={base64} alt="img banner" />}
                </div>
                <div className="image-button">
                  <Button variant="contained" component="label" size="small">
                    Pilih Image
                    <input type="file" hidden onChange={uploadImage} />
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Nama Kategori"
                  type="text"
                  fullWidth
                  size="small"
                  value={desc}
                  onChange={(e) => setDesc(e.target.value)}
                ></TextField>
              </Grid>
            </Grid>
          </div>
          <div className="form-footer">
            <Button variant="text" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={onSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
