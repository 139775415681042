import { useEffect, useState } from "react";
import "./styles.scss";
import { Button, Grid, TextField, Select, FormControl } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import message from "../../../../services/message.service";

export default function MessageForm(props) {
  const id = props.id;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [messageText, setMessageText] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  let onProcess = false;
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (id != 0) getAdmin();
  }, [id]);

  const getAdmin = async () => {
    await message
      .get({
        id: id,
      })
      .then((response) => {
        setName(response.name);
        setEmail(response.email);
        setPhone(response.phone);
        setSubject(response.subject);
        setMessageText(decodeURIComponent(response.message));
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {});
  };

  //   const onSubmit = async () => {
  //     if (onProcess) return false;
  //     setIsSubmit(true);

  //     if (username.length < 6 || name == "") {
  //       return false;
  //     }
  //     if (id == 0) {
  //       if (password.length < 6 || password != confirmPass) return false;
  //     }

  //     onProcess = true;

  //     let params = {
  //       id: id,
  //       username: username,
  //       name: name,
  //       phone: phone,
  //       status: status,
  //     };
  //     if (id == 0) {
  //       params.password = password;
  //     }

  //     await admin
  //       .submit(params)
  //       .then((response) => {
  //         alert("Simpan Data Sukses");
  //         props.onSuccess();
  //       })
  //       .catch((err) => {
  //         alert(err);
  //       })
  //       .finally(() => {
  //         onProcess = false;
  //       });
  //   };

  const onCancel = () => {
    if (onProcess) return false;
    props.onCancel();
  };

  return (
    <>
      <div className="admin-form">
        <div className="form-box">
          <div className="form-title">Message Form</div>
          <div className="form-content">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  type="text"
                  fullWidth
                  size="small"
                  value={name}
                  inputProps={{ readOnly: true }}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email"
                  type="text"
                  fullWidth
                  size="small"
                  value={email}
                  inputProps={{ readOnly: true }}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Phone"
                  type="text"
                  fullWidth
                  size="small"
                  value={phone}
                  inputProps={{ readOnly: true }}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Subject"
                  type="text"
                  fullWidth
                  size="small"
                  value={subject}
                  inputProps={{ readOnly: true }}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Message"
                  type="text"
                  fullWidth
                  size="small"
                  value={messageText}
                  multiline
                  rows={6}
                  inputProps={{ readOnly: true }}
                ></TextField>
              </Grid>
            </Grid>
          </div>
          <div className="form-footer">
            <Button variant="contained" onClick={onCancel}>
              Close
            </Button>
            {/* <Button variant="contained" onClick={onSubmit}>
              Submit
            </Button> */}
          </div>
        </div>
      </div>
    </>
  );
}
