import { Link, useLocation } from "react-router-dom";
import "./styles.scss";
import "./../../global.scss";
import { Grid } from "@mui/material";
import { NavHashLink } from "react-router-hash-link";
import { useEffect, useState } from "react";
import company from "../../services/company.service";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import service from "../../services/service.service";

function ProfileFooter() {
  const location = useLocation();
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [twitter, setTwitter] = useState("");
  const [facebook, setFacebook] = useState("");
  const [youtube, setYoutube] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [layananList, setLayananList] = useState([]);
  const [layananTotal, setLayananTotal] = useState(0);

  useEffect(() => {
    getCompany();
    getLayanan();
  }, []);

  const getLayanan = async () => {
    await service
      .listFront({ filter: "", page: 0, size: 5 })
      .then((response) => {
        setLayananList(response.records);
        setLayananTotal(response.total);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {});
  };

  const getCompany = async () => {
    await company
      .getFront({})
      .then((response) => {
        setAddress(response.address);
        setPhone(response.phone);
        setEmail(response.email);
        setTwitter(response.twitter);
        setFacebook(response.facebook);
        setYoutube(response.youtube);
        setInstagram(response.instagram);
        setLinkedin(response.linkedin);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {});
  };

  const openTab = (url) => {
    window.open(url, "_blank").focus();
  };

  return (
    <>
      <div className="max-1200 component-profile-footer dark-green-bg">
        <div className="footer-content">
          <Grid container spacing={{ xs: 2, md: 8 }} justifyContent="center">
            <Grid item xs={12} md={4}>
              <div className="footer-section-container">
                <div className="footer-section-title text-yellow text-left">
                  Kontak Kantor
                </div>
                <div className="footer-section-content">
                  <table className="tbl">
                    <tr>
                      <td className="section-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 64 64"
                          id="location"
                          height="24px"
                        >
                          <path
                            fill="#fabe47"
                            d="M32.12 52.61c.81 0 3.41-1.08 7.13-4.52 5.91-5.44 12.81-15 12.81-24a20.06 20.06 0 0 0-40.12 0c0 14.14 16.47 28.22 20.18 28.52ZM32 12.14a9.56 9.56 0 1 1-9.56 9.56A9.57 9.57 0 0 1 32 12.14Zm21.8 42.35c0 1.87-7.69 5.5-21.8 5.5s-21.8-3.63-21.8-5.5c0-1.25 3.6-3.57 11.62-4.77a1.34 1.34 0 0 0 .4-.13c3.76 3.55 7.44 5.83 9.66 6h.28c3 0 7.37-3.69 9.13-5.31.22-.2.44-.42.66-.64a2 2 0 0 0 .23.06c8.02 1.22 11.62 3.54 11.62 4.79Z"
                          ></path>
                        </svg>
                      </td>
                      <td className="section-content text-left">
                        {/* Jl. Bypass Alang-Alang Lebar KM12,
                        <br />
                        <span>
                          Pergudangan Cosmo Bizland No.C3
                          <br />
                          Palembang, Sumatera Selatan, 30154
                        </span> */}
                        {address}
                      </td>
                    </tr>
                    <tr>
                      <td className="section-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          id="phone"
                          fill="#fabe47"
                          height="24px"
                        >
                          <g data-name="Layer 2">
                            <g data-name="phone">
                              <rect width="24" height="24" opacity="0"></rect>
                              <path d="M17.4 22A15.42 15.42 0 0 1 2 6.6 4.6 4.6 0 0 1 6.6 2a3.94 3.94 0 0 1 .77.07 3.79 3.79 0 0 1 .72.18 1 1 0 0 1 .65.75l1.37 6a1 1 0 0 1-.26.92c-.13.14-.14.15-1.37.79a9.91 9.91 0 0 0 4.87 4.89c.65-1.24.66-1.25.8-1.38a1 1 0 0 1 .92-.26l6 1.37a1 1 0 0 1 .72.65 4.34 4.34 0 0 1 .19.73 4.77 4.77 0 0 1 .06.76A4.6 4.6 0 0 1 17.4 22z"></path>
                            </g>
                          </g>
                        </svg>
                      </td>
                      <td className="section-content text-left">{phone}</td>
                    </tr>
                    <tr>
                      <td className="section-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 256 256"
                          id="at"
                          height="24px"
                          fill="#fabe47"
                        >
                          <rect width="256" height="256" fill="none"></rect>
                          <circle
                            cx="127.991"
                            cy="127.995"
                            r="40"
                            fill="none"
                            stroke="#fabe47"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="24"
                          ></circle>
                          <path
                            fill="none"
                            stroke="#fabe47"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="24"
                            d="M181.07274,207.99724a96.00809,96.00809,0,1,1,42.91847-80.00212c0,22.09139-8,40-28,40s-28-17.90861-28-40v-40"
                          ></path>
                        </svg>
                      </td>
                      <td className="section-content text-left">{email}</td>
                    </tr>
                  </table>
                </div>
                <div className="social-media-container">
                  {twitter != "" && (
                    <TwitterIcon onClick={() => openTab(twitter)} />
                  )}
                  {facebook != "" && (
                    <FacebookIcon onClick={() => openTab(facebook)} />
                  )}
                  {youtube != "" && (
                    <YouTubeIcon onClick={() => openTab(youtube)} />
                  )}
                  {instagram != "" && (
                    <InstagramIcon onClick={() => openTab(instagram)} />
                  )}
                  {linkedin != "" && (
                    <LinkedInIcon onClick={() => openTab(linkedin)} />
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="footer-section-container">
                <div className="footer-section-title text-yellow text-left">
                  Layanan Kami
                </div>
                <div className="footer-section-content">
                  <ul>
                    {layananList.map((item, idx) => (
                      <li key={idx}>
                        <NavHashLink to={"/services#" + item.title}>
                          {item.title}
                        </NavHashLink>
                      </li>
                    ))}
                    {/* <li>
                      <NavHashLink to={"/services#mechanic"}>
                        Layanan Mekanik
                      </NavHashLink>
                    </li>
                    <li>
                      <NavHashLink to={"/services#electric"}>
                        Layanan Listrik
                      </NavHashLink>
                    </li> */}
                    {layananTotal > 5 && (
                      <li>
                        <NavHashLink to={"/services"}>Lainnya</NavHashLink>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div className="footer-section-container">
                <div className="footer-section-title text-yellow text-left">
                  Halaman Penting
                </div>
                <div className="footer-section-content">
                  <ul>
                    <li>
                      <Link to={"/"}>Beranda</Link>
                    </li>
                    <li>
                      <Link to={"/aboutus"}>Tentang Kami</Link>
                    </li>
                    <li>
                      <Link to={"/services"}>Layanan Kami</Link>
                    </li>
                    <li>
                      <Link to={"/rental"}>Sewa/Rental</Link>
                    </li>
                    <li>
                      <Link to={"/sell"}>Penjualan</Link>
                    </li>
                    <li>
                      <Link to={"/client"}>Klien</Link>
                    </li>
                    <li>
                      <Link to={"/contact"}>Kontak</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="copyright-container">
          &copy;{" "}
          <Link to={"/"} className="link">
            indopart.co.id
          </Link>{" "}
          , <span>All Right Reserved</span>
        </div>
      </div>
    </>
  );
}
export default ProfileFooter;
