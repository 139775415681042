import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import componentReducer from "./features/component/componentSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    component: componentReducer,
  },
});
