import "./styles.scss";
import "../../global.scss";
import ProfileTopBar from "../../components/profileTopBar";
import { Grid } from "@mui/material";
import banner1 from "../../assets/images/banner-1.jpg";
import ProfileFooter from "../../components/profileFooter";
import news from "../../services/news.service";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function News() {
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const [size, setSize] = useState(8);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getList();
  }, []);

  const getList = async () => {
    await news
      .listFront({
        filter: "",
        page: page,
        size: size,
      })
      .then((response) => {
        for (let i = 0; i < response.records.length; i++) {
          response.records[i].id = i + 1;
        }
        setList(response.records);
        setTotal(response.total);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {});
  };

  const openDetails = (id) => {
    navigate("/newsdetail/" + id);
  };

  return (
    <>
      <div className="news-page">
        <ProfileTopBar />
        <div className="space-top"></div>
        <div className="page-title dark-green-bg">Berita</div>
        <div className="max-1200 drop-shadow">
          <div className="max-1200 news-container">
            <Grid
              container
              spacing={{ xs: 2, md: 4 }}
              justifyContent="left"
              alignItems="center"
            >
              {list.map((item, idx) => (
                <Grid key={idx} item xs={12} sm={4} md={3}>
                  <div
                    className="column-container"
                    onClick={() => openDetails(item.id)}
                  >
                    <div className="news-img">
                      <img src={item.banner} alt="banner" />
                    </div>
                    <div className="news-title text-dark-green">
                      {item.subject}
                    </div>
                    <div className="news-desc">{item.intro}</div>
                    <div className="news-date">{item.date}</div>
                  </div>
                </Grid>
              ))}
              {list.length == 0 && (
                <Grid item xs={12} className="noitem">
                  Tidak ada berita saat ini
                </Grid>
              )}
            </Grid>
            <div className="loadmore-container">
              {total != list.length && (
                <button type="button" className="btn-loadmore">
                  Muat lebih banyak
                </button>
              )}
            </div>
          </div>
        </div>
        <ProfileFooter />
      </div>
    </>
  );
}

export default News;
