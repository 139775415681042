import { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import { Button, Grid, TextField, Select, FormControl } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import newsServices from "../../../../services/news.service";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import noImage from "../../../../assets/images/no-image.png";
import axios from "axios";

export default function NewsForm(props) {
  const id = props.id;

  const [base64, setBase64] = useState("");
  const [intro, setIntro] = useState("");
  const [subject, setSubject] = useState("");
  const [news, setNews] = useState("");
  const [status, setStatus] = useState(1);
  const [imgChange, setImgChange] = useState(0);

  let onProcess = false;
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (id != 0) getNews();
  }, [id]);

  const handleEditorDataChange = useCallback((data) => {
    setNews(data);
  }, []);

  function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const uploadImage = async (e) => {
    let image = e.target.files[0];
    if (image == undefined) return false;
    let fileSize = Math.round(image.size / 1024);
    if (fileSize > 2048) {
      alert("File Image tidak boleh lebih dari 2MB");
      return false;
    }

    const formData = new FormData();
    formData.append("image", image);

    axios
      .post("https://indopart.co.id/webservices/upload_image.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log(response);
        setBase64(response.path);
        setImgChange(1);
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });

    // const fr = new FileReader();
    // fr.addEventListener("load", (evt) => {
    //     setBase64(evt.target.result);
    // });
    // fr.readAsDataURL(e.target.files[0]);
    // setImgChange(1);
  };

  const getNews = async () => {
    await newsServices
      .get({
        id: id,
      })
      .then((response) => {
        setIntro(response.intro);
        setNews(response.news);
        setSubject(response.subject);
        setBase64(response.base64);
        setStatus(response.status);
        setImgChange(0);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {});
  };

  const onSubmit = async () => {
    if (onProcess) return false;
    setIsSubmit(true);

    onProcess = true;

    let params = {
      id,
      subject,
      intro,
      news,
      imagePath: base64,
      imgChange,
      status,
    };

    await newsServices
      .submit(params)
      .then((response) => {
        alert("Simpan Data Sukses");
        props.onSuccess();
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        onProcess = false;
      });
  };

  const onCancel = () => {
    if (onProcess) return false;
    props.onCancel();
  };

  return (
    <>
      <div className="news-form">
        <div className="form-box">
          <div className="form-title">Berita Form</div>
          <div className="form-content">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="preview-image-container">
                  {base64 == "" && <img src={noImage} alt="img banner" />}
                  {base64 != "" && <img src={base64} alt="img banner" />}
                </div>
                <div className="image-button">
                  <Button variant="contained" component="label" size="small">
                    Pilih Image
                    <input type="file" hidden onChange={uploadImage} />
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Subject"
                  type="text"
                  fullWidth
                  size="small"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Intro"
                  type="text"
                  fullWidth
                  multiline
                  rows={4}
                  size="small"
                  value={intro}
                  onChange={(e) => setIntro(e.target.value)}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <CKEditor
                  editor={ClassicEditor}
                  className="ckeditor-container"
                  data={news}
                  config={{
                    placeholder: "",
                  }}
                  onReady={(editor) => {
                    editor.plugins.get("FileRepository").createUploadAdapter = (
                      loader
                    ) => {
                      return {
                        upload: async () => {
                          try {
                            const file = await loader.file;
                            await new Promise((resolve) =>
                              setTimeout(resolve, 2000)
                            );
                            const base64Data = await convertFileToBase64(file);
                            return {
                              default: base64Data,
                            };
                          } catch (error) {
                            console.error("Upload failed:", error);
                          }
                        },
                      };
                    };
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleEditorDataChange(data);
                  }}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    handleEditorDataChange(data);
                  }}
                  onFocus={(event, editor) => {
                    const data = editor.getData();
                    handleEditorDataChange(data);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    labelId="status-label"
                    id="status-select"
                    value={status}
                    label="Status"
                    fullWidth
                    size="small"
                    onChange={(e) => setStatus(e.target.value)}
                    sx={{ textAlign: "left" }}
                  >
                    <MenuItem value={1}>Pending</MenuItem>
                    <MenuItem value={2}>Publish</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <div className="form-footer">
            <Button variant="text" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={onSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
