export function convertDateToString(date) {
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  month = month < 10 ? "0" + month : month;

  let day = date.getDate();
  day = day < 10 ? "0" + day : day;

  return year + "-" + month + "-" + day;
}

export const convertObjectURLToBase64 = async (objectURL) => {
  try {
    const response = await fetch(objectURL);
    const buffer = await response.arrayBuffer();
    const base64 = btoa(
      new Uint8Array(buffer).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );
    return base64;
  } catch (error) {
    console.error("Error converting object URL to base64:", error);
    return null;
  }
};

export const pageSizeOptions = [10, 25, 50];
