import { useState } from "react";
import "./styles.scss";
import MenuIcon from "@mui/icons-material/Menu";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import DefaultAvatar from "../../../assets/images/default-avatar.png";
import { Popover } from "@mui/material";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/features/auth/authSlice";
import ChangePassword from "./change-password";

function BaseLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [menuOpen, setMenuOpen] = useState(true);
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  const [formOpen, setFormOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleUserMenu = () => {
    setUserMenuOpen(!userMenuOpen);
  };

  const goTo = (route) => {
    navigate(route);
  };

  const doLogout = () => {
    dispatch(logout());
    navigate("/");
  };

  const onChangePassword = () => {
    toggleUserMenu();
    setFormOpen(true);
  };
  const closeForm = () => {
    setFormOpen(false);
  };
  const successForm = () => {
    setFormOpen(false);
  };

  return (
    <>
      <div className="base-layout">
        <div className={`navigation-container `}>
          {/* <div
            className={
              location.pathname == "/panel/dashboard"
                ? "menu-active"
                : "menu-default"
            }
            onClick={() => goTo("/panel/dashboard")}
          >
            Dashboard
          </div> */}
          <div
            className={
              location.pathname == "/panel/admin"
                ? "menu-active"
                : "menu-default"
            }
            onClick={() => goTo("/panel/admin")}
          >
            Admin
          </div>
          <div
            className={
              location.pathname == "/panel/company"
                ? "menu-active"
                : "menu-default"
            }
            onClick={() => goTo("/panel/company")}
          >
            Data Perusahaan
          </div>
          <div
            className={
              location.pathname == "/panel/service"
                ? "menu-active"
                : "menu-default"
            }
            onClick={() => goTo("/panel/service")}
          >
            Layanan
          </div>
          <div
            className={
              location.pathname == "/panel/rental-category"
                ? "menu-active"
                : "menu-default"
            }
            onClick={() => goTo("/panel/rental-category")}
          >
            Kategori Sewa/Rental
          </div>
          <div
            className={
              location.pathname == "/panel/rental"
                ? "menu-active"
                : "menu-default"
            }
            onClick={() => goTo("/panel/rental")}
          >
            Sewa/Rental
          </div>
          <div
            className={
              location.pathname == "/panel/sell-category"
                ? "menu-active"
                : "menu-default"
            }
            onClick={() => goTo("/panel/sell-category")}
          >
            Kategori Penjualan
          </div>
          <div
            className={
              location.pathname == "/panel/sell"
                ? "menu-active"
                : "menu-default"
            }
            onClick={() => goTo("/panel/sell")}
          >
            Penjualan
          </div>
          <div
            className={
              location.pathname == "/panel/client"
                ? "menu-active"
                : "menu-default"
            }
            onClick={() => goTo("/panel/client")}
          >
            Client
          </div>
          {/* <div
            className={
              location.pathname == "/panel/message"
                ? "menu-active"
                : "menu-default"
            }
            onClick={() => goTo("/panel/message")}
          >
            Pesan
          </div> */}
        </div>
        <div
          className={`navigation-floating-overlay ${
            menuOpen ? "" : "navigation-floating-overlay-close"
          }`}
        >
          <div className="navigation-floating-container">
            <div className="icon-close" onClick={toggleMenu}>
              <HighlightOffIcon fontSize="large" />
            </div>
            {/* <div
              className="menu-active"
              onClick={() => goTo("/panel/dashboard")}
            >
              Dashboard
            </div> */}
            <div
              className={
                location.pathname == "/panel/admin"
                  ? "menu-active"
                  : "menu-default"
              }
              onClick={() => goTo("/panel/admin")}
            >
              Admin
            </div>
            <div
              className={
                location.pathname == "/panel/company"
                  ? "menu-active"
                  : "menu-default"
              }
              onClick={() => goTo("/panel/company")}
            >
              Data Perusahaan
            </div>
            <div
              className={
                location.pathname == "/panel/service"
                  ? "menu-active"
                  : "menu-default"
              }
              onClick={() => goTo("/panel/service")}
            >
              Layanan
            </div>
            <div
              className={
                location.pathname == "/panel/rental-category"
                  ? "menu-active"
                  : "menu-default"
              }
              onClick={() => goTo("/panel/rental-category")}
            >
              Kategori Sewa/Rental
            </div>
            <div
              className={
                location.pathname == "/panel/rental"
                  ? "menu-active"
                  : "menu-default"
              }
              onClick={() => goTo("/panel/rental")}
            >
              Sewa/Rental
            </div>
            <div
              className={
                location.pathname == "/panel/sell-category"
                  ? "menu-active"
                  : "menu-default"
              }
              onClick={() => goTo("/panel/sell-category")}
            >
              Kategori Penjualan
            </div>
            <div
              className={
                location.pathname == "/panel/sell"
                  ? "menu-active"
                  : "menu-default"
              }
              onClick={() => goTo("/panel/sell")}
            >
              Penjualan
            </div>
            <div
              className={
                location.pathname == "/panel/client"
                  ? "menu-active"
                  : "menu-default"
              }
              onClick={() => goTo("/panel/client")}
            >
              Client
            </div>
            {/* <div
              className={
                location.pathname == "/panel/message"
                  ? "menu-active"
                  : "menu-default"
              }
              onClick={() => goTo("/panel/message")}
            >
              Pesan
            </div> */}
          </div>
        </div>
        <div className="page-container">
          <div className="top-bar">
            <div className="menu-icon-container" onClick={toggleMenu}>
              <MenuIcon fontSize="medium" />
            </div>
            <div className="system-title">ADMIN PANEL</div>
            <div className="avatar">
              <img src={DefaultAvatar} alt="avatar" onClick={toggleUserMenu} />
              {userMenuOpen && (
                <div className="user-menu-container">
                  {/* <div className="user-menu">Profile</div> */}
                  <div className="user-menu" onClick={onChangePassword}>
                    Ubah Password
                  </div>
                  <div className="user-menu" onClick={doLogout}>
                    Logout
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="page-content-container">
            <Outlet context={menuOpen} />
          </div>
        </div>
        {formOpen && (
          <ChangePassword onCancel={closeForm} onSubmit={successForm} />
        )}
      </div>
    </>
  );
}

export default BaseLayout;
