import { useState } from "react";
import "./styles.scss";
import auth from "../../../services/auth.service";
import { useDispatch } from "react-redux";
import { setToken } from "../../../store/features/auth/authSlice";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const doLogin = () => {
    if (username.length < 6) {
      alert("Username minimal 6 karakter");
      return false;
    }

    if (password.length < 6) {
      alert("Password minimal 6 karakter");
      return false;
    }

    auth
      .login({ username, password })
      .then((res) => {
        dispatch(setToken(res.token));
        navigate("/panel/", { replace: true });
      })
      .catch((err) => {
        // console.log(err);
        alert(err);
      });
  };

  return (
    <>
      <div className="login-container">
        <div className="login-box">
          <div className="login-title">Welcome to Admin Panel</div>
          <div className="login-field">
            {/* <div className="login-label">Username</div> */}
            <div className="login-input">
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="login-input">
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="login-button">
            <button type="button" onClick={doLogin}>
              LOGIN
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
