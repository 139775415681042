/* eslint-disable */
import axios from "axios";
import TokenService from "./token.service";

const loginConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

const baseUrl = "https://indopart.co.id/webservices";
let isInit = false;

export default {
  errorHandler(error) {
    if (!error.response) {
      // NETWORK ERROR
      return Promise.reject(new Error("Network Error"));
    } else if (error.response.status === 401 || error.response.status === 403) {
      // AUTH ERROR
      Promise.reject(error.response.data.error);
      // store.dispatch(LOGOUT);
      return Promise.reject(error.response.data.error);
    } else {
      // OTHER ERROR
      return Promise.reject(error.response);
    }
  },
  successHandler(response) {
    // LOGIN REQuEST
    if (!response.data.status) return Promise.resolve(response.data);
    // OTHER REQUEST
    if (response.data.status === "success")
      return Promise.resolve(response.data.data);
    return Promise.reject(response.data.message);
  },
  setHeader() {
    let tokenObject = TokenService.getToken();
    if (!tokenObject) {
      // this.$store.dispatch(LOGOUT);
    } else {
      axios.defaults.headers.common["Authorization"] = "bearer " + tokenObject;
    }
  },
  removeHeader() {
    delete axios.defaults.headers.common["Authorization"];
  },
  init() {
    axios.defaults.baseURL = baseUrl;
    axios.interceptors.response.use(
      (response) => this.successHandler(response),
      (error) => this.errorHandler(error)
    );
    this.setHeader();
    isInit = true;
  },
  buildQuery(qObject) {
    let retVal = "";
    let keys = Object.keys(qObject);
    keys.forEach((x, i) => {
      if (i === 0) retVal += `?${x}=${qObject[x]}`;
      else retVal += `&${x}=${qObject[x]}`;
    });
    return retVal;
  },
  get(resource, query) {
    if (!isInit) this.init();
    if (query) resource += this.buildQuery(query);
    return axios.get(resource);
  },

  post(resource, params, query = null) {
    if (!isInit) this.init();
    if (query) resource += this.buildQuery(query);
    return axios.post(resource, params);
  },

  put(resource, params, query) {
    if (!isInit) this.init();
    if (query) resource += this.buildQuery(query);
    return axios.put(resource, params);
  },

  download(resource, params, config, query) {
    if (!isInit) this.init();
    if (query) resource += this.buildQuery(query);
    return axios.post(resource, params, config);
  },

  delete(resource, query) {
    if (!isInit) this.init();
    if (query) resource += this.buildQuery(query);
    return axios.delete(resource);
  },

  login(username, password) {
    var payload =
      "username=" + username + "&password=" + password + "&grant_type=password";
    return axios.post("token", payload, loginConfig);
  },
};
