import "./styles.scss";
import "../../global.scss";
import ProfileTopBar from "../../components/profileTopBar";
import { Grid } from "@mui/material";
import banner from "../../assets/images/service/intro-banner.jpg";
import ProfileFooter from "../../components/profileFooter";
import Mechanic from "../../assets/images/service/mechanic.jpg";
import Electric from "../../assets/images/service/electric.jpg";
import clientService from "../../services/client.service";
import { useEffect, useState } from "react";

export default function Client() {
  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    getClient();
  }, []);

  const getClient = async () => {
    await clientService
      .listFront({ filter: "", page: 0, size: 0 })
      .then((response) => {
        setClientList(response.records);
        // setLayananTotal(response.total);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {});
  };

  return (
    <>
      <div className="client-page">
        <ProfileTopBar />
        <div className="space-top"></div>
        <div className="page-title dark-green-bg">Klien</div>
        <div className="max-1200 drop-shadow">
          <div className="client-section-content">
            <div className="client-content">
              <Grid
                container
                spacing={{ xs: 2, md: 4 }}
                justifyContent="center"
                alignItems="center"
              >
                {clientList.map((item, idx) => (
                  <Grid
                    key={idx}
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    className="client-column"
                  >
                    <div className="img-container">
                      <img src={item.image} alt={item.name} />
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>
          </div>
        </div>
        <ProfileFooter />
      </div>
    </>
  );
}
