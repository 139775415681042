import axios from "axios";
import baseServices from "./base.service";

const login = (params) => {
  return new Promise((resolve, reject) => {
    baseServices
      .post("/login.php", params)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const getInfo = (params) => {
  return new Promise((resolve, reject) => {
    baseServices
      .post("/info.php", params)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const changePassword = (params) => {
  return new Promise((resolve, reject) => {
    baseServices
      .post("/change_password.php", params)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const auth = {
  login,
  getInfo,
  changePassword,
};

export default auth;
