import "./styles.scss";
import "../../global.scss";
import ProfileTopBar from "../../components/profileTopBar";
import { Grid } from "@mui/material";
import banner1 from "../../assets/images/banner-1.jpg";
import ProfileFooter from "../../components/profileFooter";
import companyService from "../../services/company.service";
import { useEffect, useState } from "react";

function About() {
  const [vision, setVision] = useState("");
  const [legal, setLegal] = useState("");
  const [imageList, setImageList] = useState([]);
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    getLayanan();
  }, []);

  const getLayanan = async () => {
    await companyService
      .getFront({ filter: "", page: 0, size: 5 })
      .then((response) => {
        setVision(response.vision);
        setLegal(response.legal);
        setImageList(response.imageList);
        setCurrentImage(0);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {});
  };

  const changeCurrentImage = (imageIdx) => {
    setCurrentImage(imageIdx);
  };

  return (
    <>
      <div className="about-page">
        <ProfileTopBar />
        <div className="space-top"></div>
        <div className="page-title dark-green-bg">Tentang Kami</div>
        <div className="max-1200 drop-shadow">
          <div className="max-1200 about-intro-container">
            <Grid
              container
              spacing={{ xs: 2, md: 8 }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item xs={12} sm={5} md={4}>
                <img src={banner1} alt="banner" className="intro-img" />
              </Grid>
              <Grid item xs={12} sm={7} md={8}>
                <div className="intro-title text-yellow text-left">
                  Selamat Datang di PT. Indopart Karya Industri
                </div>
                <div className="intro-desc text-justify">
                  Kami adalah perusahaan layanan mekanikal dan elektrikal yang
                  didedikasikan untuk menyediakan solusi terbaik untuk semua
                  jenis kebutuhan mekanikal dan elektrikal.
                  <br />
                  <br />
                  Dengan komitmen terhadap keunggulan dan kepuasan pelanggan,
                  kami mendedikasikan perusahaan kami sebagai mitra yang andal
                  dan dapat dipercaya di industri ini.
                  <br />
                  <br />
                  Tim profesional yang terampil dan teknologi canggih
                  memungkinkan kami untuk memberikan layanan yang luar biasa
                  kepada klien berbagai sektor.
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="max-1200 vision-container">
            <div className="overlay"></div>
            <Grid
              container
              spacing={{ xs: 2, md: 8 }}
              justifyContent="center"
              className="grid-container"
            >
              <Grid item xs={12} md={7}>
                <div className="vision-content">
                  <div className="vision-title text-yellow text-left">
                    Visi &amp; Misi Perusahaan
                  </div>
                  <div
                    className="vision-desc text-left"
                    dangerouslySetInnerHTML={{ __html: vision }}
                  ></div>
                </div>
              </Grid>
              <Grid item xs={12} md={5}></Grid>
            </Grid>
          </div>
          <div className="max-1200 legal-container">
            <Grid
              container
              spacing={{ xs: 2, md: 8 }}
              justifyContent="center"
              className="grid-container"
            >
              <Grid item xs={12} sm={6}>
                <div className="legal-content">
                  <div className="legal-title text-yellow text-left">
                    Legalitas
                  </div>
                  <div
                    className="legal-desc text-left"
                    dangerouslySetInnerHTML={{ __html: legal }}
                  ></div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="rental-item-image-container">
                  <div className="preview-container">
                    {imageList.length > 0 && (
                      <img
                        src={imageList[currentImage]}
                        alt={"prev-" + currentImage}
                        className="rental-item-image"
                      />
                    )}
                  </div>
                  {imageList.length > 0 && (
                    <div className="thumbnail-container">
                      {imageList.map((item2, idx2) => (
                        <img
                          key={idx2}
                          src={item2}
                          alt={"img" + idx2}
                          className="thumbnail-img"
                          onClick={() => changeCurrentImage(idx2)}
                        />
                      ))}
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <ProfileFooter />
      </div>
    </>
  );
}

export default About;
