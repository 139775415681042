import { useEffect, useState } from "react";
import "./styles.scss";
import { Button, Grid, TextField, Select, FormControl } from "@mui/material";
import admin from "../../../../services/admin.service";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";

export default function AdminForm(props) {
  const id = props.id;

  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [phone, setPhone] = useState("");
  const [status, setStatus] = useState(1);
  let onProcess = false;
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (id != 0) getAdmin();
  }, [id]);

  const getAdmin = async () => {
    await admin
      .get({
        id: id,
      })
      .then((response) => {
        setName(response.name);
        setUsername(response.username);
        setPhone(response.phone);
        setStatus(response.status);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {});
  };

  const onSubmit = async () => {
    if (onProcess) return false;
    setIsSubmit(true);

    if (username.length < 6 || name == "") {
      return false;
    }
    if (id == 0) {
      if (password.length < 6 || password != confirmPass) return false;
    }

    onProcess = true;

    let params = {
      id: id,
      username: username,
      name: name,
      phone: phone,
      status: status,
    };
    if (id == 0) {
      params.password = password;
    }

    await admin
      .submit(params)
      .then((response) => {
        alert("Simpan Data Sukses");
        props.onSuccess();
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        onProcess = false;
      });
  };

  const onCancel = () => {
    if (onProcess) return false;
    props.onCancel();
  };

  return (
    <>
      <div className="admin-form">
        <div className="form-box">
          <div className="form-title">Admin Form</div>
          <div className="form-content">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Username"
                  type="text"
                  fullWidth
                  size="small"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                ></TextField>
                {isSubmit && username.length < 6 && (
                  <div className="alert">Username minimal 6 karakter</div>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Name"
                  type="text"
                  fullWidth
                  size="small"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></TextField>
                {isSubmit && name == "" && (
                  <div className="alert">Name harus diisi</div>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone"
                  type="text"
                  fullWidth
                  size="small"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                ></TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    labelId="status-label"
                    id="status-select"
                    value={status}
                    label="Status"
                    fullWidth
                    size="small"
                    onChange={(e) => setStatus(e.target.value)}
                    sx={{ textAlign: "left" }}
                  >
                    <MenuItem value={1}>Aktif</MenuItem>
                    <MenuItem value={2}>Suspend</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              {id == 0 && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Password"
                    type="password"
                    fullWidth
                    size="small"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  ></TextField>
                  {isSubmit && password.length < 6 && (
                    <div className="alert">Password minimal 6 karakter</div>
                  )}
                </Grid>
              )}
              {id == 0 && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Confirm Password"
                    type="password"
                    fullWidth
                    size="small"
                    value={confirmPass}
                    onChange={(e) => setConfirmPass(e.target.value)}
                  ></TextField>
                  {isSubmit && password != confirmPass && (
                    <div className="alert">
                      Password dan Confirm password tidak sama
                    </div>
                  )}
                </Grid>
              )}
            </Grid>
          </div>
          <div className="form-footer">
            <Button variant="text" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={onSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
