import "./styles.scss";
import "../../global.scss";
import ProfileTopBar from "../../components/profileTopBar";
import { Grid } from "@mui/material";
import banner1 from "../../assets/images/banner-1.jpg";
import ProfileFooter from "../../components/profileFooter";
import rentalService from "../../services/rental.service";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import imgGenset from "../../assets/images/rental/genset.jpg";
import imgAlatberat from "../../assets/images/rental/alatberat.jpg";
import { Description } from "@mui/icons-material";
import rentalCategoryService from "../../services/rentalCategory.service";
import noImage from "../../assets/images/no-image.png";

function Rental() {
  const navigate = useNavigate();

  const [category, setCategory] = useState(1);
  const [page, setPage] = useState(0);
  const [size, setSize] = useState(0);
  const [list, setList] = useState([]);
  const [total, setTotal] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [isUpdated, setIsUpdated] = useState(0);

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {
    getList(category);
  }, [category]);

  const getCategory = async () => {
    await rentalCategoryService
      .listFront({
        filter: "",
        page: 0,
        size: 0,
      })
      .then((response) => {
        setCategoryList(response.records);
        if (response.records.length > 0) setCategory(response.records[0].id);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {});
  };

  const getList = async (category) => {
    await rentalService
      .listFront({
        category,
        page,
        size,
      })
      .then((response) => {
        // for (let i = 0; i < response.records.length; i++) {
        //   response.records[i].id = i + 1;
        // }

        setList(response.records);
        setTotal(response.total);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {});
  };

  const changeCategory = (categoryId) => {
    if (category == categoryId) return false;
    setCategory(categoryId);
    getList(categoryId);
  };

  const changeCurrentImage = (listIdx, imageIdx) => {
    let listItem = list;

    if (listItem[listIdx].currentImage == imageIdx) return false;
    listItem[listIdx].currentImage = imageIdx;

    setList(listItem);
    setIsUpdated(isUpdated + 1);
  };

  return (
    <>
      <div className="rental-page">
        <ProfileTopBar />
        <div className="space-top"></div>
        <div className="page-title dark-green-bg">Sewa & Rental</div>
        <div className="max-1200 drop-shadow">
          <div className="max-1200 rental-container">
            <div className="rental-category-container">
              <table className="table-category">
                <tr>
                  {categoryList.map((item, idx) => (
                    <td className="column-category">
                      <div
                        key={idx}
                        className={`rental-category ${
                          category == item.id ? "rental-category-active" : ""
                        }`}
                        onClick={() => changeCategory(item.id)}
                      >
                        <div>
                          <img src={item.image} alt={item.desc}></img>
                          {item.desc}
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
              </table>
              {/* {categoryList.map((item, idx) => (
                <div
                  key={idx}
                  className={`rental-category ${
                    category == item.id ? "rental-category-active" : ""
                  }`}
                  onClick={() => changeCategory(item.id)}
                >
                  <div>
                    <img src={item.image} alt={item.desc}></img>
                    {item.desc}
                  </div>
                </div>
              ))} */}
            </div>
            <div className="rental-item-container">
              {list.length == 0 && (
                <div className="rental-item-empty">Tidak ada barang</div>
              )}

              {list.map((item, idx) => (
                <Grid
                  key={idx}
                  container
                  spacing={{ xs: 2, md: 4 }}
                  justifyContent="left"
                  alignItems="center"
                  className="rental-item-grid"
                >
                  <Grid
                    item
                    xs={12}
                    sm={5}
                    md={4}
                    className="rental-item-image-container"
                  >
                    <div className="preview-container">
                      {item.imageList.length > 0 && (
                        <img
                          src={item.imageList[item.currentImage]}
                          alt={item.name + item.currentImage}
                          className="rental-item-image"
                        />
                      )}
                      {item.imageList.length == 0 && (
                        <img
                          src={noImage}
                          alt="no image"
                          className="rental-item-image"
                        />
                      )}
                    </div>
                    {item.imageList.length > 0 && (
                      <div className="thumbnail-container">
                        {item.imageList.map((item2, idx2) => (
                          <img
                            key={idx2}
                            src={item2}
                            alt={"img" + idx2}
                            className="thumbnail-img"
                            onClick={() => changeCurrentImage(idx, idx2)}
                          />
                        ))}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={7} md={8} classname="rental-item-spec">
                    <div className="rental-item-name">{item.name}</div>
                    {/* <div className="rental-item-category">
                      Kategori: {item.categoryName}
                    </div> */}
                    <div
                      className="rental-item-desc"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    ></div>
                  </Grid>
                </Grid>
              ))}
            </div>
          </div>
        </div>
        <ProfileFooter />
      </div>
    </>
  );
}

export default Rental;
