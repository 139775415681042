import { createSlice } from "@reduxjs/toolkit";

export const componentSlice = createSlice({
  name: "component",
  initialState: {
    alert: {
      open: false,
      message: "",
      severity: "success", //error, warning, info, success
      duration: 5000,
    },
    common: {
      loadConfigJson: false,
    },
  },
  reducers: {
    setAlert: (state, action) => {
      state.alert = action.payload;
    },
    setLoadConfigJson: (state = this.initialState, action) => {
      console.log("in store", action.payload);
      return {
        ...state,
        common: { ...state.common, loadConfigJson: action.payload },
      };
    },
  },
});

export const { setAlert, setLoadConfigJson } = componentSlice.actions;
export const getAlert = (state) => state.component.alert;
export const getLoadConfigJson = (state) =>
  state.component.common.loadConfigJson;

export default componentSlice.reducer;
