import "./styles.scss";
import "../../global.scss";
import ProfileTopBar from "../../components/profileTopBar";
import { Grid } from "@mui/material";
import banner1 from "../../assets/images/banner-1.jpg";
import ProfileFooter from "../../components/profileFooter";
import company from "../../services/company.service";
import { useEffect, useState } from "react";

function Contact() {
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    getCompany();
  }, []);

  const getCompany = async () => {
    await company
      .getFront({})
      .then((response) => {
        setAddress(response.address);
        setPhone(response.phone);
        setEmail(response.email);
        // setTwitter(response.twitter);
        // setFacebook(response.facebook);
        // setYoutube(response.youtube);
        // setInstagram(response.instagram);
        // setLinkedin(response.linkedin);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {});
  };

  return (
    <>
      <div className="contact-page">
        <ProfileTopBar />
        <div className="space-top"></div>
        <div className="page-title dark-green-bg">KONTAK</div>
        <div className="max-1200 drop-shadow">
          <div className="max-1200 contact-container">
            <div className="contact-intro">
              Silahkan tinggalkan pesan atau pertanyaan yang Anda miliki,
              <br /> kami akan segera membalas pesan atau pertanyaan Anda.
            </div>
            <Grid
              container
              spacing={0}
              className="grid-container dark-green-bg"
            >
              <Grid xs={12} sm={6}>
                <div className="kontak-container ">
                  <div className="kontak-field-container">
                    <div className="kontak-title">WORKSHOP</div>
                    <div className="kontak-desc">{address}</div>
                  </div>
                  <div className="kontak-field-container">
                    <div className="kontak-title">KONTAK</div>
                    <div className="kontak-desc">{phone}</div>
                  </div>
                  <div className="kontak-field-container">
                    <div className="kontak-title">EMAIL</div>
                    <div className="kontak-desc">{email}</div>
                  </div>
                </div>
              </Grid>
              <Grid xs={12} sm={6}>
                <form
                  method="post"
                  enctype="text/plain"
                  action="mailto:ptindopartkaryaindustri@yahoo.com"
                >
                  <div className="form-container">
                    <div className="form-kontak-field">
                      <input type="text" placeholder="Nama Anda" name="nama" />
                    </div>
                    <div className="form-kontak-field">
                      <input
                        type="email"
                        placeholder="Email Anda"
                        name="email"
                      />
                    </div>
                    <div className="form-kontak-field">
                      <input
                        type="text"
                        placeholder="Phone no. Anda"
                        name="phone"
                      />
                    </div>
                    <div className="form-kontak-field">
                      <input type="text" placeholder="Perihal" name="perihal" />
                    </div>
                    <div className="form-kontak-field">
                      <textarea
                        rows={6}
                        placeholder="Pesan"
                        name="pesan"
                      ></textarea>
                    </div>
                    <div className="form-kontak-field">
                      <button type="submit">Kirim Pesan</button>
                    </div>
                  </div>
                </form>
              </Grid>
            </Grid>
          </div>
        </div>
        <ProfileFooter />
      </div>
    </>
  );
}

export default Contact;
