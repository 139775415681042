import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "./styles.scss";
import Logo from "../../assets/images/indopart-logo.png";
import { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { blue, grey } from "@mui/material/colors";

function ProfileTopBar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    // console.log(location.pathname);
    if (searchParams.get("page") == "login") {
      navigate("/login");
    }
  }, [searchParams]);

  const goTo = (path) => {
    navigate(path);
  };

  const openMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <>
      <div className="component-profile-top-bar">
        <div className="top-bar-content">
          <div className="left-container">
            <img
              src={Logo}
              alt="logo"
              className="logo"
              onClick={() => goTo("/")}
            />
          </div>
          <div className="nav-container">
            <div
              className={`${
                location.pathname == "/" ? "nav-active" : "nav-default"
              }`}
              onClick={() => goTo("/")}
            >
              BERANDA
            </div>
            <div
              className={`${
                location.pathname == "/aboutus" ? "nav-active" : "nav-default"
              }`}
              onClick={() => goTo("/aboutus")}
            >
              TENTANG KAMI
            </div>
            <div
              className={`${
                location.pathname == "/services" ? "nav-active" : "nav-default"
              }`}
              onClick={() => goTo("/services")}
            >
              LAYANAN KAMI
            </div>
            {/* <div
              className={`${
                location.pathname == "/business" ? "nav-active" : "nav-default"
              }`}
              onClick={() => goTo("/business")}
            >
              SEKTOR BISNIS
            </div> */}
            {/* <div
              className={`${
                location.pathname == "/news" ? "nav-active" : "nav-default"
              }`}
              onClick={() => goTo("/news")}
            >
              BERITA
            </div> */}
            <div
              className={`${
                location.pathname == "/rental" ? "nav-active" : "nav-default"
              }`}
              onClick={() => goTo("/rental")}
            >
              SEWA/RENTAL
            </div>
            <div
              className={`${
                location.pathname == "/sell" ? "nav-active" : "nav-default"
              }`}
              onClick={() => goTo("/sell")}
            >
              PENJUALAN
            </div>
            <div
              className={`${
                location.pathname == "/client" ? "nav-active" : "nav-default"
              }`}
              onClick={() => goTo("/client")}
            >
              KLIEN
            </div>
            <div
              className={`${
                location.pathname == "/contact" ? "nav-active" : "nav-default"
              }`}
              onClick={() => goTo("/contact")}
            >
              KONTAK
            </div>
          </div>
          <div className="right-container">
            <MenuIcon
              onClick={openMobileMenu}
              sx={{ color: mobileMenuOpen ? blue[500] : grey[700] }}
              fontSize="large"
            />
          </div>
          {mobileMenuOpen && (
            <div className="mobile-menu-container">
              <div
                className={`${
                  location.pathname == "/"
                    ? "mobile-menu-active"
                    : "mobile-menu-default"
                }`}
                onClick={() => goTo("/")}
              >
                BERANDA
              </div>
              <div
                className={`${
                  location.pathname == "/aboutus"
                    ? "mobile-menu-active"
                    : "mobile-menu-default"
                }`}
                onClick={() => goTo("/aboutus")}
              >
                TENTANG KAMI
              </div>
              <div
                className={`${
                  location.pathname == "/services"
                    ? "mobile-menu-active"
                    : "mobile-menu-default"
                }`}
                onClick={() => goTo("/services")}
              >
                LAYANAN KAMI
              </div>
              {/* <div
                className={`${
                  location.pathname == "/news"
                    ? "mobile-menu-active"
                    : "mobile-menu-default"
                }`}
                onClick={() => goTo("/news")}
              >
                BERITA
              </div> */}
              <div
                className={`${
                  location.pathname == "/Rental"
                    ? "mobile-menu-active"
                    : "mobile-menu-default"
                }`}
                onClick={() => goTo("/rental")}
              >
                SEWA/RENTAL
              </div>
              <div
                className={`${
                  location.pathname == "/sell"
                    ? "mobile-menu-active"
                    : "mobile-menu-default"
                }`}
                onClick={() => goTo("/sell")}
              >
                PENJUALAN
              </div>
              <div
                className={`${
                  location.pathname == "/client"
                    ? "mobile-menu-active"
                    : "mobile-menu-default"
                }`}
                onClick={() => goTo("/client")}
              >
                KLIEN
              </div>
              <div
                className={`${
                  location.pathname == "/contact"
                    ? "mobile-menu-active"
                    : "mobile-menu-default"
                }`}
                onClick={() => goTo("/contact")}
              >
                KONTAK
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default ProfileTopBar;
