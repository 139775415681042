import "./styles.scss";
import "../../global.scss";
import ProfileTopBar from "../../components/profileTopBar";
import { Grid } from "@mui/material";
import banner from "../../assets/images/service/intro-banner.jpg";
import ProfileFooter from "../../components/profileFooter";
import Mechanic from "../../assets/images/service/mechanic.jpg";
import Electric from "../../assets/images/service/electric.jpg";
import service from "../../services/service.service";
import { useEffect, useState } from "react";
import noImage from "../../assets/images/no-image.png";

export default function Service() {
  const [layananList, setLayananList] = useState([]);
  const [layananTotal, setLayananTotal] = useState(0);
  const [isUpdated, setIsUpdated] = useState(0);

  useEffect(() => {
    getLayanan();
  }, []);

  const getLayanan = async () => {
    await service
      .listFront({ filter: "", page: 0, size: 0 })
      .then((response) => {
        setLayananList(response.records);
        setLayananTotal(response.total);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {});
  };

  const changeCurrentImage = (listIdx, imageIdx) => {
    let listItem = layananList;

    if (listItem[listIdx].currentImage == imageIdx) return false;
    listItem[listIdx].currentImage = imageIdx;

    setLayananList(listItem);
    setIsUpdated(isUpdated + 1);
  };

  return (
    <>
      <div className="service-page">
        <ProfileTopBar />
        <div className="space-top"></div>
        <div className="page-title dark-green-bg">Layanan Kami</div>
        <div className="max-1200 drop-shadow">
          <div className="max-1200 service-intro-container">
            <Grid
              container
              spacing={{ xs: 0 }}
              justifyContent="center"
              alignItems="center"
              className="service-grid"
            >
              <Grid item xs={12} md={7}>
                <img src={banner} alt="banner" className="service-img" />
              </Grid>
              <Grid item xs={12} md={5}>
                <div className="service-desc text-justify">
                  PT. Indopart Karya Industri memiliki tim profesional yang
                  sangat terampil dan berpengalaman dalam teknologi terbaru dan
                  praktik terbaik industri.
                  <br />
                  <br />
                  Insinyur, teknisi dan staf pendukung kami terus dilatih untuk
                  memastikan mereka memberi layanan dengan kualitas terbaik
                  kepada klien kami.
                </div>
              </Grid>
            </Grid>
          </div>
          {layananList.map((item, idx) => {
            if (idx % 2 == 0) {
              return (
                <>
                  <div className="max-1200 mechanic-container" id={item.title}>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 8 }}
                      justifyContent="center"
                      alignItems="center"
                      className="service-grid"
                    >
                      <Grid
                        item
                        xs={12}
                        sm={5}
                        md={4}
                        className="service-item-image-container"
                      >
                        <div className="preview-container">
                          {item.imageList.length > 0 && (
                            <img
                              src={item.imageList[item.currentImage]}
                              alt={item.name + item.currentImage}
                              className="service-item-image"
                            />
                          )}
                          {item.imageList.length == 0 && (
                            <img
                              src={noImage}
                              alt="no image"
                              className="service-item-image"
                            />
                          )}
                        </div>
                        {item.imageList.length > 0 && (
                          <div className="thumbnail-container">
                            {item.imageList.map((item2, idx2) => (
                              <img
                                key={idx2}
                                src={item2}
                                alt={"img" + idx2}
                                className="thumbnail-img"
                                onClick={() => changeCurrentImage(idx, idx2)}
                              />
                            ))}
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={7} md={8}>
                        <div className="mechanic-title text-yellow text-left">
                          {item.title}
                        </div>
                        <div
                          className="mechanic-desc text-justify"
                          dangerouslySetInnerHTML={{ __html: item.desc }}
                        ></div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="max-1200 separator-container">
                    <div className="separator"></div>
                  </div>
                </>
              );
            } else {
              return (
                <>
                  <div className="max-1200 electric-container" id={item.title}>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 8 }}
                      justifyContent="center"
                      alignItems="center"
                      className="service-grid electric-desktop"
                    >
                      <Grid item xs={12} sm={7} md={8}>
                        <div className="electric-title text-yellow text-left">
                          {item.title}
                        </div>
                        <div
                          className="electric-desc text-justify"
                          dangerouslySetInnerHTML={{ __html: item.desc }}
                        ></div>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={5}
                        md={4}
                        className="service-item-image-container"
                      >
                        <div className="preview-container">
                          {item.imageList.length > 0 && (
                            <img
                              src={item.imageList[item.currentImage]}
                              alt={item.name + item.currentImage}
                              className="service-item-image"
                            />
                          )}
                          {item.imageList.length == 0 && (
                            <img
                              src={noImage}
                              alt="no image"
                              className="service-item-image"
                            />
                          )}
                        </div>
                        {item.imageList.length > 0 && (
                          <div className="thumbnail-container">
                            {item.imageList.map((item2, idx2) => (
                              <img
                                key={idx2}
                                src={item2}
                                alt={"img" + idx2}
                                className="thumbnail-img"
                                onClick={() => changeCurrentImage(idx, idx2)}
                              />
                            ))}
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 8 }}
                      justifyContent="center"
                      alignItems="center"
                      className="service-grid electric-mobile"
                    >
                      <Grid
                        item
                        xs={12}
                        sm={5}
                        md={4}
                        className="service-item-image-container"
                      >
                        <div className="preview-container">
                          {item.imageList.length > 0 && (
                            <img
                              src={item.imageList[item.currentImage]}
                              alt={item.name + item.currentImage}
                              className="service-item-image"
                            />
                          )}
                          {item.imageList.length == 0 && (
                            <img
                              src={noImage}
                              alt="no image"
                              className="service-item-image"
                            />
                          )}
                        </div>
                        {item.imageList.length > 0 && (
                          <div className="thumbnail-container">
                            {item.imageList.map((item2, idx2) => (
                              <img
                                key={idx2}
                                src={item2}
                                alt={"img" + idx2}
                                className="thumbnail-img"
                                onClick={() => changeCurrentImage(idx, idx2)}
                              />
                            ))}
                          </div>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={7} md={8}>
                        <div className="electric-title text-yellow text-left">
                          {item.title}
                        </div>
                        <div
                          className="electric-desc text-justify"
                          dangerouslySetInnerHTML={{ __html: item.desc }}
                        ></div>
                      </Grid>
                    </Grid>
                  </div>
                  <div className="max-1200 separator-container">
                    <div className="separator"></div>
                  </div>
                </>
              );
            }
          })}
        </div>
        <ProfileFooter />
      </div>
    </>
  );
}
