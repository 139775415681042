import axios from "axios";
import baseServices from "./base.service";

const list = (params) => {
  return new Promise((resolve, reject) => {
    baseServices
      .post("/sell_category_list.php", params)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
const listFront = (params) => {
  return new Promise((resolve, reject) => {
    baseServices
      .post("/sell_category_list_front.php", params)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
const get = (params) => {
  return new Promise((resolve, reject) => {
    baseServices
      .post("/sell_category_get.php", params)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const submit = (params) => {
  return new Promise((resolve, reject) => {
    baseServices
      .post("/sell_category_submit.php", params)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const remove = (params) => {
  return new Promise((resolve, reject) => {
    baseServices
      .post("/sell_category_delete.php", params)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const sellCategoryService = {
  list,
  get,
  submit,
  remove,
  listFront,
};

export default sellCategoryService;
