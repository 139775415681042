import logo from "./logo.svg";
import "./App.scss";
import { CssBaseline } from "@mui/material";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Services from "./pages/services";
import News from "./pages/news";
import Contact from "./pages/contact";
import Login from "./pages/panel/login";
import BaseLayout from "./pages/panel/layout/base";
import Dashboard from "./pages/panel/dashboard";
import Admin from "./pages/panel/admin";
import Message from "./pages/panel/message";
import Company from "./pages/panel/company";
import NewsPanel from "./pages/panel/news";
import NewsDetail from "./pages/news-detail";
import ServicePanel from "./pages/panel/service";
import Rental from "./pages/rental";
import RentalPanel from "./pages/panel/rental";
import Sell from "./pages/sell";
import SellPanel from "./pages/panel/sell";
import ClientPanel from "./pages/panel/client";
import Client from "./pages/client";
import RentalCategoryPanel from "./pages/panel/rentalCategory";
import SellCategoryPanel from "./pages/panel/sellCategory";

function App() {
  return (
    <div className="App">
      <CssBaseline>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/news" element={<News />} />
          <Route path="/newsdetail/:id" element={<NewsDetail />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/rental" element={<Rental />} />
          <Route path="/sell" element={<Sell />} />
          <Route path="/client" element={<Client />} />
          <Route path="/login" element={<Login />} />
          <Route path="panel" element={<BaseLayout />}>
            <Route index element={<Navigate to="company" replace />} />
            {/* <Route path="dashboard" element={<Dashboard />} /> */}
            <Route path="admin" element={<Admin />} />
            <Route path="message" element={<Message />} />
            <Route path="company" element={<Company />} />
            <Route path="news" element={<NewsPanel />} />
            <Route path="service" element={<ServicePanel />} />
            <Route path="rental" element={<RentalPanel />} />
            <Route path="sell" element={<SellPanel />} />
            <Route path="client" element={<ClientPanel />} />
            <Route path="rental-category" element={<RentalCategoryPanel />} />
            <Route path="sell-category" element={<SellCategoryPanel />} />
          </Route>
        </Routes>
      </CssBaseline>
    </div>
  );
}

export default App;
