import "./styles.scss";
import "../../global.scss";
import ProfileTopBar from "../../components/profileTopBar";
import { Button, Grid } from "@mui/material";
import banner1 from "../../assets/images/banner-1.jpg";
import ProfileFooter from "../../components/profileFooter";
import newsServices from "../../services/news.service";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

function NewsDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [subject, setSubject] = useState("");
  const [news, setNews] = useState("");
  const [banner, setBanner] = useState("");

  useEffect(() => {
    getNews();
  }, [id]);

  const getNews = async () => {
    await newsServices
      .getFront({
        id,
      })
      .then((response) => {
        setSubject(response.subject);
        setNews(response.news);
        setBanner(response.banner);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {});
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="news-detail-page">
        <ProfileTopBar />
        <div className="space-top"></div>
        <div className="page-title dark-green-bg">Berita</div>
        <div className="max-1200 drop-shadow">
          <div className="max-1200 news-detail-container">
            <div className="back-container">
              <Button variant="text" onClick={goBack}>
                <ArrowBackIosIcon /> Kembali
              </Button>
            </div>
            <div className="img-container">
              {banner != "" && <img src={banner} alt="banner" />}
            </div>
            <div className="news-detail-title">{subject}</div>
            <div
              className="news-detail-desc"
              dangerouslySetInnerHTML={{ __html: news }}
            ></div>
          </div>
        </div>
        <ProfileFooter />
      </div>
    </>
  );
}

export default NewsDetail;
