import { createSlice } from "@reduxjs/toolkit";
import TokenService from "../../../services/token.service";
import BaseService from "../../../services/base.service";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    loginInfo: null,
    token: null,
  },
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
      TokenService.saveToken(action.payload);
      BaseService.setHeader();
    },
    setLoginInfo: (state, action) => {
      state.loginInfo = action.payload;
    },
    logout: (state) => {
      state.loginInfo = null;
      state.token = null;
      TokenService.destroyToken();
      BaseService.removeHeader();
    },
  },
});

export const { setToken, setLoginInfo, logout } = authSlice.actions;
export const getLoginInfo = (state) => state.auth.loginInfo;

export default authSlice.reducer;
