import { useEffect, useState, useCallback } from "react";
import "./styles.scss";
import { Button, Grid, TextField, Select, FormControl } from "@mui/material";
import company from "../../../services/company.service";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import noImage from "../../../assets/images/no-image.png";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

export default function Company() {
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [twitter, setTwitter] = useState("");
  const [facebook, setFacebook] = useState("");
  const [youtube, setYoutube] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [vision, setVision] = useState("");
  const [legal, setLegal] = useState("");
  const [imageList, setImageList] = useState([]);
  const [isUpdated, setIsUpdated] = useState(0);
  let onProcess = false;

  const handleEditorDataChange = useCallback((data) => {
    setLegal(data);
  }, []);

  function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const getCompany = async () => {
    await company
      .get({})
      .then((response) => {
        setAddress(response.address);
        setEmail(response.email);
        setPhone(response.phone);
        setTwitter(response.twitter);
        setFacebook(response.facebook);
        setYoutube(response.youtube);
        setInstagram(response.instagram);
        setLinkedin(response.linkedin);
        setVision(response.vision);
        setLegal(response.legal);
        setImageList(response.imageList);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {});
  };

  useEffect(() => {
    getCompany();
  }, []);

  const addImageToList = (imgUrl) => {
    let imgList = imageList;
    imgList.push(imgUrl);
    setImageList(imgList);
    setIsUpdated(isUpdated + 1);
  };

  const removeImageFromList = (idx) => {
    let imgList = imageList;
    imgList.splice(idx, 1);
    setImageList(imgList);
    setIsUpdated(isUpdated + 1);
  };

  const uploadImage = async (e) => {
    if (e.target.files.length == 0) return false;
    let image = e.target.files[0];
    if (image == undefined) return false;
    let fileSize = Math.round(image.size / 1024);

    if (fileSize > 2048) {
      alert("File Image tidak boleh lebih dari 2MB");
      return false;
    }

    const formData = new FormData();
    formData.append("image", image);

    axios
      .post("https://indopart.co.id/webservices/upload_image.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // setBase64(response.path);
        addImageToList(response.path);
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });

    // const fr = new FileReader();
    // fr.addEventListener("load", (evt) => {
    //     setBase64(evt.target.result);
    // });
    // fr.readAsDataURL(e.target.files[0]);
    // setImgChange(1);
  };

  const onSubmit = async () => {
    if (address == "") {
      alert("Alamat harus diisi");
      return false;
    }

    if (email == "") {
      alert("Email harus diisi");
      return false;
    }

    if (phone == "") {
      alert("Phone harus diisi");
      return false;
    }

    onProcess = true;

    let params = {
      address: address,
      email: email,
      phone: phone,
      twitter: twitter,
      facebook,
      youtube,
      instagram,
      linkedin,
      vision,
      legal,
      imageList,
    };

    await company
      .submit(params)
      .then((response) => {
        alert("Simpan Data Sukses");
        getCompany();
        document.getElementById("inputFile").value = "";
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        onProcess = false;
      });
  };

  const onReset = () => {
    getCompany();
  };

  return (
    <>
      <div className="company-page base-page">
        <div className="base-page-title">Data Perusahaan</div>
        <div className="base-page-content">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Alamat"
                type="text"
                fullWidth
                size="small"
                multiline
                rows={4}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                type="text"
                fullWidth
                size="small"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone"
                type="text"
                fullWidth
                size="small"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Twitter"
                type="text"
                fullWidth
                size="small"
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Facebook"
                type="text"
                fullWidth
                size="small"
                value={facebook}
                onChange={(e) => setFacebook(e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Youtube"
                type="text"
                fullWidth
                size="small"
                value={youtube}
                onChange={(e) => setYoutube(e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Instagram"
                type="text"
                fullWidth
                size="small"
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="LinkedIn"
                type="text"
                fullWidth
                size="small"
                value={linkedin}
                onChange={(e) => setLinkedin(e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Visi & Misi"
                type="text"
                fullWidth
                multiline
                rows={4}
                size="small"
                value={vision}
                onChange={(e) => setVision(e.target.value)}
              ></TextField>
            </Grid>
            <Grid item xs={12}>
              <CKEditor
                editor={ClassicEditor}
                data={legal}
                config={{
                  placeholder: "Legalitas",
                }}
                onReady={(editor) => {
                  editor.plugins.get("FileRepository").createUploadAdapter = (
                    loader
                  ) => {
                    return {
                      upload: async () => {
                        try {
                          const file = await loader.file;
                          await new Promise((resolve) =>
                            setTimeout(resolve, 2000)
                          );
                          const base64Data = await convertFileToBase64(file);
                          return {
                            default: base64Data,
                          };
                        } catch (error) {
                          console.error("Upload failed:", error);
                        }
                      },
                    };
                  };
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  handleEditorDataChange(data);
                }}
                onBlur={(event, editor) => {
                  const data = editor.getData();
                  handleEditorDataChange(data);
                }}
                onFocus={(event, editor) => {
                  const data = editor.getData();
                  handleEditorDataChange(data);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="preview-image-container">
                {imageList.map((item, idx) => (
                  <div key={idx} className="img-box">
                    <img src={item} alt={"img" + idx} />
                    <div
                      className="close-container"
                      onClick={() => removeImageFromList(idx)}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                ))}
                {imageList.length == 0 && (
                  <div className="img-box">
                    <img src={noImage} alt="img banner" />
                  </div>
                )}
              </div>
              <div className="image-button">
                <Button variant="contained" component="label" size="small">
                  Pilih Image
                  <input
                    type="file"
                    id="inputFile"
                    hidden
                    onChange={uploadImage}
                  />
                </Button>
              </div>
            </Grid>
            <Grid items xs={12} className="button-container">
              <Button variant="text" onClick={onReset}>
                Reset
              </Button>
              <Button variant="contained" onClick={onSubmit}>
                Simpan
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
