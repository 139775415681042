import axios from "axios";
import baseServices from "./base.service";

const get = (params) => {
  return new Promise((resolve, reject) => {
    baseServices
      .post("/company_get.php", params)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const getFront = (params) => {
  return new Promise((resolve, reject) => {
    baseServices
      .post("/company_get_front.php", params)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const submit = (params) => {
  return new Promise((resolve, reject) => {
    baseServices
      .post("/company_submit.php", params)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const company = {
  get,
  submit,
  getFront,
};

export default company;
