import { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import { Button, Grid, TextField, Select, FormControl } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import rentalService from "../../../../services/rental.service";
import rentalCategoryService from "../../../../services/rentalCategory.service";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import noImage from "../../../../assets/images/no-image.png";
import axios from "axios";
import CloseIcon from "@mui/icons-material/Close";

export default function RentalForm(props) {
  const id = props.id;

  const [base64, setBase64] = useState("");
  const [categoryId, setCategoryId] = useState(1);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState(1);
  const [imgChange, setImgChange] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [isUpdated, setIsUpdated] = useState(0);

  let onProcess = false;
  const [isSubmit, setIsSubmit] = useState(false);

  useEffect(() => {
    if (id != 0) getRental();
    getRentalCategory();
  }, [id]);

  const handleEditorDataChange = useCallback((data) => {
    setDescription(data);
  }, []);

  function convertFileToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  const addImageToList = (imgUrl) => {
    let imgList = imageList;
    imgList.push(imgUrl);
    setImageList(imgList);
    setIsUpdated(isUpdated + 1);
  };

  const removeImageFromList = (idx) => {
    let imgList = imageList;
    imgList.splice(idx, 1);
    setImageList(imgList);
    setIsUpdated(isUpdated + 1);
  };

  const uploadImage = async (e) => {
    let image = e.target.files[0];
    if (image == undefined) return false;
    let fileSize = Math.round(image.size / 1024);
    if (fileSize > 2048) {
      alert("File Image tidak boleh lebih dari 2MB");
      return false;
    }

    const formData = new FormData();
    formData.append("image", image);

    axios
      .post("https://indopart.co.id/webservices/upload_image.php", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        // setBase64(response.path);
        addImageToList(response.path);
        setImgChange(1);
      })
      .catch((error) => {
        console.log(error);
        alert(error);
      });

    // const fr = new FileReader();
    // fr.addEventListener("load", (evt) => {
    //     setBase64(evt.target.result);
    // });
    // fr.readAsDataURL(e.target.files[0]);
    // setImgChange(1);
  };

  const getRentalCategory = async () => {
    await rentalCategoryService
      .list({
        filter: "",
        page: 0,
        size: 0,
      })
      .then((response) => {
        setCategoryList(response.records);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {});
  };

  const getRental = async () => {
    await rentalService
      .get({
        id: id,
      })
      .then((response) => {
        setDescription(response.description);
        setName(response.name);
        setStatus(response.status);
        setCategoryId(response.categoryId);
        setImageList(response.imageList);
        setImgChange(0);
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {});
  };

  const onSubmit = async () => {
    if (onProcess) return false;
    setIsSubmit(true);

    onProcess = true;

    let params = {
      id,
      categoryId,
      name,
      description,
      imageList,
      imgChange,
      status,
    };

    await rentalService
      .submit(params)
      .then((response) => {
        alert("Simpan Data Sukses");
        props.onSuccess();
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        onProcess = false;
      });
  };

  const onCancel = () => {
    if (onProcess) return false;
    props.onCancel();
  };

  return (
    <>
      <div className="rental-form">
        <div className="form-box">
          <div className="form-title">Sewa/Rental Form</div>
          <div className="form-content">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="preview-image-container">
                  {imageList.map((item, idx) => (
                    <div key={idx} className="img-box">
                      <img src={item} alt={"img" + idx} />
                      <div
                        className="close-container"
                        onClick={() => removeImageFromList(idx)}
                      >
                        <CloseIcon />
                      </div>
                    </div>
                  ))}
                  {imageList.length == 0 && (
                    <div className="img-box">
                      <img src={noImage} alt="img banner" />
                    </div>
                  )}
                </div>
                <div className="image-button">
                  <Button variant="contained" component="label" size="small">
                    Pilih Image
                    <input type="file" hidden onChange={uploadImage} />
                  </Button>
                </div>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Nama Barang"
                  type="text"
                  fullWidth
                  size="small"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="category-label">Kategori</InputLabel>
                  <Select
                    labelId="category-label"
                    id="category-select"
                    value={categoryId}
                    label="Kategori"
                    fullWidth
                    size="small"
                    onChange={(e) => setCategoryId(e.target.value)}
                    sx={{ textAlign: "left" }}
                  >
                    {categoryList.map((item, idx) => (
                      <MenuItem key={idx} value={item.id}>
                        {item.desc}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <CKEditor
                  editor={ClassicEditor}
                  className="ckeditor-container"
                  data={description}
                  config={{
                    placeholder: "",
                    mediaEmbed: {
                      previewsInData: true,
                    },
                  }}
                  onReady={(editor) => {
                    editor.plugins.get("FileRepository").createUploadAdapter = (
                      loader
                    ) => {
                      return {
                        upload: async () => {
                          try {
                            const file = await loader.file;
                            await new Promise((resolve) =>
                              setTimeout(resolve, 2000)
                            );
                            const base64Data = await convertFileToBase64(file);
                            return {
                              default: base64Data,
                            };
                          } catch (error) {
                            console.error("Upload failed:", error);
                          }
                        },
                      };
                    };
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    handleEditorDataChange(data);
                  }}
                  onBlur={(event, editor) => {
                    const data = editor.getData();
                    handleEditorDataChange(data);
                  }}
                  onFocus={(event, editor) => {
                    const data = editor.getData();
                    handleEditorDataChange(data);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="status-label">Status</InputLabel>
                  <Select
                    labelId="status-label"
                    id="status-select"
                    value={status}
                    label="Status"
                    fullWidth
                    size="small"
                    onChange={(e) => setStatus(e.target.value)}
                    sx={{ textAlign: "left" }}
                  >
                    <MenuItem value={1}>Draft</MenuItem>
                    <MenuItem value={2}>Publish</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <div className="form-footer">
            <Button variant="text" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={onSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
