import { useCallback, useEffect, useState } from "react";
import "./styles.scss";
import { Button, Grid, TextField, Select, FormControl } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import auth from "../../../../services/auth.service";

export default function ChangePassword(props) {
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  let onProcess = false;
  const [isSubmit, setIsSubmit] = useState(false);

  const onSubmit = async () => {
    if (onProcess) return false;

    if (oldPass == "") {
      alert("Invalid password lama");
      return false;
    }

    if (newPass == "") {
      alert("Invalid password baru");
      return false;
    }

    if (newPass != confirmPass) {
      alert("Password baru dan Konfirm password tidak sama");
      return false;
    }

    setIsSubmit(true);

    onProcess = true;

    let params = {
      oldPass,
      newPass,
    };

    await auth
      .ChangePassword(params)
      .then((response) => {
        alert("Ubah Password Sukses");
        props.onSuccess();
      })
      .catch((err) => {
        alert(err);
      })
      .finally(() => {
        onProcess = false;
      });
  };

  const onCancel = () => {
    if (onProcess) return false;
    props.onCancel();
  };

  return (
    <>
      <div className="change-password-form">
        <div className="form-box">
          <div className="form-title">Ubah Password</div>
          <div className="form-content">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Password Lama"
                  type="text"
                  fullWidth
                  size="small"
                  value={oldPass}
                  onChange={(e) => setOldPass(e.target.value)}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Password Baru"
                  type="text"
                  fullWidth
                  size="small"
                  value={newPass}
                  onChange={(e) => setNewPass(e.target.value)}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Konfirm Password"
                  type="text"
                  fullWidth
                  size="small"
                  value={confirmPass}
                  onChange={(e) => setConfirmPass(e.target.value)}
                ></TextField>
              </Grid>
            </Grid>
          </div>
          <div className="form-footer">
            <Button variant="text" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="contained" onClick={onSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
