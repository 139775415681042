import axios from "axios";
import baseServices from "./base.service";

const list = (params) => {
  return new Promise((resolve, reject) => {
    baseServices
      .post("/client_list.php", params)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
const listFront = (params) => {
  return new Promise((resolve, reject) => {
    baseServices
      .post("/client_list_front.php", params)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
const get = (params) => {
  return new Promise((resolve, reject) => {
    baseServices
      .post("/client_get.php", params)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
// const getFront = (params) => {
//   return new Promise((resolve, reject) => {
//     baseServices
//       .post("/rental_get_front.php", params)
//       .then(function (response) {
//         resolve(response);
//       })
//       .catch(function (error) {
//         reject(error);
//       });
//   });
// };

const submit = (params) => {
  return new Promise((resolve, reject) => {
    baseServices
      .post("/client_submit.php", params)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const remove = (params) => {
  return new Promise((resolve, reject) => {
    baseServices
      .post("/client_delete.php", params)
      .then(function (response) {
        resolve(response);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

const clientService = {
  list,
  get,
  submit,
  remove,
  listFront,
  //   getFront,
};

export default clientService;
